"use client";
import { createTheme, darken } from "@mui/material/styles";

import { josefin } from "./app/fonts";
const fonts = `${josefin.style.fontFamily}, 'Helevetica', "Arial", 'sans-serif'`;

import { colorOrange, colorText100, colorText50 } from "theme/colors";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 760,
      md: 960,
      lg: 1280,
      xl: 1400,
    },
  },
  spacing: 4,
  palette: {
    primary: {
      main: "#1BA8CE",
      dark: "#164194",
      contrastText: colorText50,
    },
    secondary: {
      main: "#FC9527",
      contrastText: colorText50,
    },
    text: {
      primary: "#2A364B",
      secondary: "#536382",
      disabled: "#D3DDE7",
    },
    divider: colorText100,
    background: {
      paper: colorText50,
      default: colorText50,
    },
  },
  typography: {
    htmlFontSize: 16,
    fontFamily: fonts,
    fontSize: 14,
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightBold: 600,
    h1: {
      fontFamily: fonts,
      fontWeight: 600,
      fontSize: "2.4rem",
      lineHeight: 1.3,
      letterSpacing: "0em",
      color: "#164194",
    },
    h2: {
      fontFamily: fonts,
      fontWeight: 600,
      fontSize: "2.3rem",
      lineHeight: 1.2,
      letterSpacing: "0em",
      color: "#164194",
    },
    h3: {
      fontFamily: fonts,
      fontWeight: 600,
      fontSize: "2rem",
      lineHeight: 1.1,
      letterSpacing: "0em",
      color: "#164194",
    },
    h4: {
      fontFamily: fonts,
      fontWeight: 600,
      fontSize: "1.55rem",
      lineHeight: 1.32,
      letterSpacing: "0.00735em",
      color: "#164194",
    },
    h5: {
      fontFamily: fonts,
      fontWeight: 600,
      fontSize: "1.45rem",
      lineHeight: 1.3,
      letterSpacing: "0em",
      color: "#164194",
    },
    h6: {
      fontFamily: fonts,
      fontWeight: 600,
      fontSize: "1.35rem",
      lineHeight: 1.41,
      letterSpacing: "0.0075em",
      color: "#164194",
    },
    subtitle1: {
      fontFamily: fonts,
      fontWeight: 600,
      fontSize: "1.2rem",
      lineHeight: 1.25,
      letterSpacing: "0",
    },
    subtitle2: {
      fontFamily: fonts,
      fontWeight: 600,
      fontSize: "1.06rem",
      lineHeight: 1.45,
      letterSpacing: "0",
    },
    body1: {
      fontFamily: fonts,
      fontWeight: 400,
      fontSize: "0.97rem",
      lineHeight: 1.54,
      letterSpacing: "0.00938em",
    },
    body2: {
      fontFamily: fonts,
      fontWeight: 400,
      fontSize: "0.86rem",
      lineHeight: 1.42,
      letterSpacing: "0.01071em",
    },
    button: {
      fontFamily: fonts,
      fontWeight: 600,
      fontSize: "0.95rem",
      lineHeight: 1.75,
      letterSpacing: "0.02857em",
      textTransform: "initial",
    },
    caption: {
      fontFamily: fonts,
      fontWeight: 400,
      fontSize: "0.75rem",
      lineHeight: 1.2,
      letterSpacing: "0",
    },
    overline: {
      fontFamily: fonts,
      fontWeight: 600,
      fontSize: "0.75rem",
      lineHeight: 2.66,
      letterSpacing: "0.08333em",
      textTransform: "uppercase",
    },
  },
  zIndex: {
    mobileStepper: 1000,
    speedDial: 1020,
    appBar: 1025,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
  },
  shadows: [
    "none",
    "0px 2px 1px -1px rgba(113, 141, 152, 0.1),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(113, 141, 152, 0.05)",
    "0px 3px 1px -2px rgba(113, 141, 152, 0.1),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(113, 141, 152, 0.05)",
    "0px 3px 3px -2px rgba(113, 141, 152, 0.1),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(113, 141, 152, 0.05)",
    "0px 2px 4px -1px rgba(113, 141, 152, 0.1),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(113, 141, 152, 0.05)",
    "0px 3px 5px -1px rgba(113, 141, 152, 0.1),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(113, 141, 152, 0.05)",
    "0px 3px 5px -1px rgba(113, 141, 152, 0.1),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(113, 141, 152, 0.05)",
    "0px 4px 5px -2px rgba(113, 141, 152, 0.1),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(113, 141, 152, 0.05)",
    "0px 5px 5px -3px rgba(113, 141, 152, 0.1),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(113, 141, 152, 0.05)",
    "0px 5px 6px -3px rgba(113, 141, 152, 0.1),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(113, 141, 152, 0.05)",
    "0px 6px 6px -3px rgba(113, 141, 152, 0.1),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(113, 141, 152, 0.05)",
    "0px 6px 7px -4px rgba(113, 141, 152, 0.1),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(113, 141, 152, 0.05)",
    "0px 7px 8px -4px rgba(113, 141, 152, 0.1),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(113, 141, 152, 0.05)",
    "0px 7px 8px -4px rgba(113, 141, 152, 0.1),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(113, 141, 152, 0.05)",
    "0px 7px 9px -4px rgba(113, 141, 152, 0.1),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(113, 141, 152, 0.05)",
    "0px 8px 9px -5px rgba(113, 141, 152, 0.1),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(113, 141, 152, 0.05)",
    "0px 8px 10px -5px rgba(113, 141, 152, 0.1),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(113, 141, 152, 0.05)",
    "0px 8px 11px -5px rgba(113, 141, 152, 0.1),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(113, 141, 152, 0.05)",
    "0px 9px 11px -5px rgba(113, 141, 152, 0.1),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(113, 141, 152, 0.05)",
    "0px 9px 12px -6px rgba(113, 141, 152, 0.1),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(113, 141, 152, 0.05)",
    "0px 10px 13px -6px rgba(113, 141, 152, 0.1),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(113, 141, 152, 0.05)",
    "0px 10px 13px -6px rgba(113, 141, 152, 0.1),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(113, 141, 152, 0.05)",
    "0px 10px 14px -6px rgba(113, 141, 152, 0.1),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(113, 141, 152, 0.05)",
    "0px 11px 14px -7px rgba(113, 141, 152, 0.1),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(113, 141, 152, 0.05)",
    "0px 11px 15px -7px rgba(113, 141, 152, 0.1),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(113, 141, 152, 0.05)",
  ],
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          "&.MuiButton-containedPrimary": {
            "&:hover": {
              backgroundColor: darken(colorOrange, 0.1),
            },
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: "9px 9px 9px 0",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          "&.MuiInput-input": {
            "&:focus": {
              backgroundColor: "transparent",
            },
          },
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: "hover",
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minWidth: "80px",
        },
      },
    },
  },
});

export default theme;
